<template>
  <div class="adminPage">
    <router-view />
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.adminPage {
  height: 100%;
}
</style>
